import {
  FEATURE_FLAG_CANVAS,
  FEATURE_FLAG_CHATGPT,
  FEATURE_FLAG_DYNAMIC_ACTOR,
  FEATURE_FLAG_FORMAT,
  FEATURE_FLAG_STYLE,
  FEATURE_FLAG_BACKGROUND_COLOR,
  FEATURE_FLAG_CROP_ACTOR,
  FEATURE_FLAG_STARTERS_USE_PREMIUM_VOICES,
  FEATURE_FLAG_SIGNUP
} from './config';

export const featureFlags = {
  signUp: FEATURE_FLAG_SIGNUP === 'true',
  canvas: FEATURE_FLAG_CANVAS === 'true',
  format: FEATURE_FLAG_FORMAT === 'true',
  style: FEATURE_FLAG_STYLE === 'true',
  chatgpt: FEATURE_FLAG_CHATGPT === 'true',
  dynamicAvatars: FEATURE_FLAG_DYNAMIC_ACTOR === 'true',
  backgroundColor: FEATURE_FLAG_BACKGROUND_COLOR === 'true',
  cropActor: FEATURE_FLAG_CROP_ACTOR === 'true',
  startersUsePremiumVoices: FEATURE_FLAG_STARTERS_USE_PREMIUM_VOICES === 'true'
};
